import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import Divider from '../../sections/akkadian/Divider';
import imgBanner from "../../assets/image/akkadian/specialistsimage.jpg";
import docInfo from "../../assets/docs/Patient_Info.pdf";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';
import PatientAction from "../../sections/akkadian/PatientBlock";


const AlliedHealth = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >

	
        <Helmet>
          <title>Akkadian Health - ADHD Coaching & Psychology | Telehealth Australia</title>
          <meta
            name="description"
            content="Affordable ADHD Coaching and Psychology Services in Australia via telehealth. Comprehensive support for ADHD and mental health."
          />
        </Helmet>

        <div className="inner-banner">
          <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                <div className="section-title text-center px-md-12 mb-lg-6 mb-5">
                  <h1 className="title gr-text-4 mb-3 mb-lg-4">ADHD Coaching & Psychology Services</h1>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mt-md-3 pt-12 pt-lg-14">
              <Col xs="2">
                <div className="banner-fluid-image pt-lg-6"></div>
              </Col>
              <Col xs="8">
                <div className="banner-fluid-image pt-lg-6">
                  <img src={imgBanner} alt="" className="d-flex text-center w-100 rounded-12" />
                </div>
              </Col>
              <Col xs="2">
                <div className="banner-fluid-image pt-lg-6"></div>
              </Col>
            </Row>

            <Row className="my-10 mb-lg-12 pr-xl-10">
              <Col lg="4">
                <h2 className="title gr-text-5 mb-3">
                  Why choose Akkadian Health for ADHD Coaching & Psychology?
                </h2>
              </Col>
              <Col lg="8">
                <h3 className="gr-text-8">Cost Competitive</h3>
                <p className="gr-text-10 mb-3 mb-lg-5">
                  As a private clinic, we try to keep our fees (and out-of-pocket expenses) as low as possible.
                  They are currently amongst the lowest fees in Australia for this type of service.
                </p>
                <p className="gr-text-10 mb-7 mb-lg-10">
                  Please refer to our <Link to={`/faq#fees`} className={`gr-text-12`}>
                    FAQ
                  </Link>{' '}
                  for the latest FEE SCHEDULE under "<i>What are your fees?</i>"
                </p>

                <h3 className="gr-text-8">Comprehensive</h3>
                <p className="gr-text-10 mb-3 mb-lg-5">
                  Our telehealth sessions are conducted by highly experienced, registered psychologists and accredited ADHD Coaches in
                  Australia.
                </p>

                <h3 className="gr-text-8">Convenient, fast & efficient</h3>
                <p className="gr-text-10 mb-3 mb-lg-5">
                  Our sessions are delivered through accessible telehealth (video conference) services by
                  our dedicated team of over 20 clinicians with minimal wait times.
                </p>
              </Col>
            </Row>

            </Container>

       <Divider />
      <Container>
			<Row className="justify-content-center py-lg-10 py-8">
			  <Col lg="12">
				<h2 className="title gr-text-5 mb-7">
					Things You Need to Know Before Proceeding
				</h2>
				
				
				<div>
				<h3 className="gr-text-8">Self Referrals</h3>
				<p className="gr-text-12 mb-7 mb-lg-4">
				<strong>ADHD Coaching is only available via self-referral.</strong> Simply contact us to arrange an appointment.
				</p>
				<p className="gr-text-12 mb-11 mb-lg-9">
				Please note: Self referrals for Psychology services are also possible; however, we encourage you to seek advice frm your GP and obtain a referral along with a Mental Health Care/Treatment Plan to access available Medicare rebates. 
				</p>          
				<h3 className="gr-text-8">Valid Referrals</h3>
				<p className="gr-text-12 mb-7 mb-lg-4">
				<strong>Referrals associated with Mental Health Care/Treatment Plans for Psychology services must be addressed to 'Akkadian Health'.</strong> They must contain sufficient information about your medical history and presenting complaint.
				</p>
				<p className="gr-text-12 mb-11 mb-lg-9">
				Please note: We will need your Medicare Number for Psychology services under a Mental Health Care/Treatment Plan. If you are not registered for Medicare in Australia or you are accessing Psychology services under self-referral, you will be required to pay the full private fees as Medicare rebates will not apply. 
				</p>
									
				</div>
			
			  </Col>
			</Row>				
			
          </Container>
        </div>
        <Divider />
                <div className="px-12 text-center py-7 py-lg-10">
                  <p className="gr-text-8 mb-1">
						Please review our new <Link to={`/faq`} className={`gr-text-9`}> FAQ </Link> for more detailed information.
				  </p>
				 </div>
				 <div className="px-12 text-center mb-8 mb-lg-10">
				 <Link to={`/faq`}>
					<Button variant="warning" className="gr-hover-y w-80 w-sm-auto">FAQ</Button>
				 </Link>
				</div>
 
      </PageWrapper>
    </>
  );
};
export default AlliedHealth;

